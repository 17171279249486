import React from 'react'
import { graphql, Link } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import Layout from '@components/Layout'
import {
  Section,
  SectionTopHeader,
  SectionWrapper,
  NarrowSection,
  SectionHeader,
  SectionParagraph,
  SectionHeading,
  SectionGridTimelineLayout,
  SectionBackgroundHero,
  SectionGridLayout,
  SectionStyler,
  SectionAnimated,
} from '@components/Section'
import { CallToButton } from '@components/Button'
import Seo from '@components/SEO'
import AssistedLivingServiceResultsSection from '@sections/assisted-living/assisted-living-service-results'
import AssistedLivingCTAFooter from '@sections/assisted-living/assisted-living-cta-footer'
import Vector from '@components/Vector'

export const query = graphql`
  query {
    heroImage: file(
      name: { eq: "assisted-living-hero" }
      relativeDirectory: { eq: "industries/assisted-living" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

interface Props {
  data: {
    heroImage: {
      name: String
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

function AssistedLivingClientPage({ data }: Props) {
  const { heroImage } = data
  const colorOverride = '#000'

  return (
    <Layout>
      <Seo
        image={heroImage}
        url="/clients/assisted-living"
        title="Assisted Living"
        description="We specialize in working with Assisted Living communities to implement unique marketing campaigns that bring quality leads."
      />

      <SectionBackgroundHero
        backgroundImageData={heroImage.childImageSharp.gatsbyImageData}
      >
        <SectionTopHeader>
          <SectionWrapper>
            <SectionHeader color={'#fff'}>Assisted Living</SectionHeader>
            {/* <SectionHeading.h1 color={"#fff"}>
              Boost Your Facility Occupancy
            </SectionHeading.h1> */}
            <SectionHeading.h1 color={'#fff'}>
              Get 15-30 New Resident Leads In 30 Days Guaranteed
            </SectionHeading.h1>
            <SectionParagraph colorOverride={'#fff'}>
              Auotiv specializes in automated resident acquisition with reliable
              and effective lead generation systems designed for Assisted Living
              communities.
            </SectionParagraph>
            <CallToButton
              to="/schedule"
              title="Get started"
              aria-label="Get started"
            >
              Get started!
            </CallToButton>
          </SectionWrapper>
        </SectionTopHeader>
      </SectionBackgroundHero>

      {/* Key Notes

      - Talk about the RESULTS you'll get them, as icon each
      - At high level, how does lead generation get those results? More leads = more residents = $$$ 
      - Specific FAQ on deliverables, eg. how long does service delivery take? 
      - do NOT talk about HOW we do it. make them curious. don't mention google ads or whatever!!!! 

      */}

      <NarrowSection medium style={{ marginTop: '100px', textAlign: 'center' }}>
        <SectionHeader>The Results We Get You</SectionHeader>
        <SectionHeading.h3>How We Help</SectionHeading.h3>
        <SectionParagraph>
          We provide Assisted Living communities with proven results with new
          residents, creating more opportunities for your community to grow and
          succeed.
        </SectionParagraph>
        <SectionGridLayout>
          <div>
            <Vector
              name="auotiv-cloud-user-group"
              alt="An image of a group of people to represent targeting ideal customers to drive in new residents"
            />
            <SectionHeading.h4>New Residents</SectionHeading.h4>
            <SectionParagraph>
              We precisely target the ideal customers specifically for your
              community, driving in quality new residents to fill your
              vacancies.
            </SectionParagraph>
          </div>

          <div>
            <Vector
              name="auotiv-fund-accounting"
              alt="An image of a budget pie to represent flexible ad spend budget"
            />
            <SectionHeading.h4>Flexible Budget</SectionHeading.h4>
            <SectionParagraph>
              You can choose exactly how much you want to spend to attract new
              residents into your facility so you can easily scale when you see
              the results you want.
            </SectionParagraph>
          </div>

          <div>
            <Vector
              name="auotiv-security-shield"
              alt="An image of a security shield to represent protection and money back guarantee"
            />
            <SectionHeading.h4>Money-Back Guarantee</SectionHeading.h4>
            <SectionParagraph>
              We stand behind our services. We offer a money-back guarantee
              policy because we are confident that we'll be able to deliver you
              results.
            </SectionParagraph>
          </div>
        </SectionGridLayout>
      </NarrowSection>

      <NarrowSection medium style={{ marginTop: '100px', textAlign: 'center' }}>
        <SectionHeader>Our Systems and Processes</SectionHeader>
        <SectionHeading.h3>Industry Targeted Optimizations</SectionHeading.h3>
        <SectionParagraph>
          We are consistently monitoring the Assisted Living industry for key
          opportunities to improve our lead generation systems and
          processes so our clients can focus on providing quality care for their
          residents!
        </SectionParagraph>

        <SectionGridTimelineLayout>
          <ul>
            <li>
              <div>
                <SectionHeading.h4>Step 1</SectionHeading.h4>
                <Vector
                  name="auotiv-web-analytics"
                  alt="An image of a laptop search to represent targeting ideal customers"
                />
              </div>
              <div>
                <SectionHeading.h4>Market Insights</SectionHeading.h4>
                <SectionParagraph>
                  We constantly gather market insights in the Assisted Living
                  industry using our proprietary software technology.
                </SectionParagraph>
              </div>
              <Vector
                name="icons8-down-arrow"
                alt="An image of an down arrow pie to represent the step after this one"
                width={50}
                height={50}
              />
            </li>

            <li>
              <div>
                <SectionHeading.h4>Step 2</SectionHeading.h4>
                <Vector
                  name="auotiv-stopwatch"
                  alt="An image of a stopwatch to represent optimizing advertising campaigns"
                />
              </div>
              <div>
                <SectionHeading.h4>Optimization</SectionHeading.h4>
                <SectionParagraph>
                  With those market insights, we run optimizations on our
                  advertising campaigns and strategy.
                </SectionParagraph>
              </div>
              <Vector
                name="icons8-down-arrow"
                alt="An image of an down arrow pie to represent the step after this one"
                width={50}
                height={50}
              />
            </li>
            <li>
              <div>
                <SectionHeading.h4>Step 3</SectionHeading.h4>
                <Vector
                  name="auotiv-process"
                  alt="An image of a circular arrow to represent analyzing and then repeating the whole process"
                />
              </div>
              <div>
                <SectionHeading.h4>Analyze & Repeat</SectionHeading.h4>
                <SectionParagraph>
                  After optimizing, we analyze our performance and make
                  appropriate adjustments to finetune our systems and processes
                  for success. We then repeat the process to capture and
                  evaluate new opportunties.
                </SectionParagraph>
              </div>
            </li>
          </ul>
        </SectionGridTimelineLayout>
      </NarrowSection>

      {/* Future Sections */}
      {/* Insert Sample Client Metrics */}
      {/* Insert Brand Logo of Clients */}

      {/* Insert Reviews and Testimonies of Clients */}
      {/* Insert Review Link @ Trustpilot */}
      {/* Insert Year to Date Results - Maybe Animate - Would be cool for home page too!*/}
      {/* Insert Footer CTA - Link*/}

      <SectionAnimated>
        <SectionStyler>
          <SectionHeading.h3 colorOverride={colorOverride}>
            Get More Residents!
          </SectionHeading.h3>
          <SectionParagraph>
            Start taking the initiative towards improving your resident lead
            generation process at your Assisted Living community today!
          </SectionParagraph>
          <CallToButton
            to="/schedule"
            title="Get started"
            aria-label="Get started"
          >
            Get started!
          </CallToButton>
        </SectionStyler>
      </SectionAnimated>
    </Layout>
  )
}

export default AssistedLivingClientPage
