import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

interface VectorProps {
  name: string
  horizontalInvert?: boolean
  alt: string
  border?: boolean
  width?: number
  height?: number
}

const Vector: React.FC<VectorProps> = ({
  name,
  horizontalInvert,
  alt,
  border = false,
  width,
  height,
}) => {
  const data = useStaticQuery(graphql`
    query GetVectors {
      banners: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
          relativeDirectory: { in: ["banner/arabica", "banner/general"] }
        }
      ) {
        totalCount
        edges {
          node {
            name
            extension
            publicURL
          }
        }
      }
      icons: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
          relativeDirectory: {
            in: [
              "icons/cute-icons"
              "icons/pastel-icons"
              "icons/navigation-icons"
            ]
          }
        }
      ) {
        totalCount
        edges {
          node {
            name
            extension
            publicURL
          }
        }
      }
    }
  `)

  const vectorMap = {}

  data.banners.edges.forEach((edge) => {
    const { node } = edge
    vectorMap[node.name] = node
  })

  data.icons.edges.forEach((edge) => {
    const { node } = edge
    vectorMap[node.name] = node
  })

  const styles: any = {
    margin: '0 auto',
  }

  if (horizontalInvert) {
    styles.transform = 'scaleX(-1)'
  }

  if (width) {
    styles.width = width
  }

  if (height) {
    styles.height = height
  }

  if (border) {
    styles.border = '1px solid #e3e3e3'
  }

  return <img alt={alt} src={vectorMap[name].publicURL} style={styles} />
}

export default Vector
